$buybox-margin-top: 36px;
$buybox-label-margin-bottom: 5px;
$buybox-box-top-margin:20px;
$buybox-box-bottom-margin:20px;
$buybox-button-height:32px;
$buybox-content-items-padding-top: 20px;
$buybox-store-description-margin-bottom:8px;
$buybox-wishlist-width:48px;
$buybox-wishlist-width-with-padding:60px;
$buybox-product-status-padding-top:18px;
$buybox-quantity-input:5px;
$buybox-incr-decr:5px;
$buybox-wishlist-top-postion:2px;
$gutter-width: 40px;
$media-gallery-width:66%;
$msv-dialog-width: 400px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;

$carousel-control-prev-icon-bg: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$msv-black}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"), "#", "%23");
$carousel-control-next-icon-bg: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$msv-black}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"), "#", "%23");

// mixin
@mixin buybox-button {
  height: $buybox-button-height;
  width: 100%;
}

//style presets
:root {
  --msv-accent-brand-color: #{$msv-primary};
  --msv-font-primary-color: #{$msv-black};
  --msv-font-secondary-color: #{$msv-black};
  --msv-border-color: transparent;

  --msv-body-font-size-l: #{$msv-font-size-l};
  --msv-body-font-size-xxl: #{$msv-font-size-xxl};

  --msv-buybox-btn-bg: #{$msv-white};
  --msv-buybox-btn-font-color: var(--msv-font-primary-color);
  --msv-buybox-btn-border-color: var(--msv-border-color);
  --msv-buybox-btn-font-size: var(--msv-body-font-size-l);

  //title
  --msv-buybox-title-font-size: var(--msv-body-font-size-xxl);
  --msv-buybox-title-font-color: var(--msv-font-primary-color);

  // rating star
  --msv-buybox-rating-star: var(--msv-font-primary-color);

  --msv-buybox-btn-wishlist-color: var(--msv-accent-brand-color);

  //text
  --msv-buybox-text-font-size: var(--msv-body-font-size-m);
  --msv-buybox-text-font-color: var(--msv-font-primary-color);
  --msv-font-weight-light: #{$msv-font-weight-light};
  --msv-buybox-title-font-size: #{$msv-font-size-xxl};
  --msv-buybox-text-font-size: #{$msv-font-size-m};

  // primary button
  --msv-buybox-primary-btn-bg: var(--msv-accent-brand-color);
  --msv-buybox-primary-btn-font-color: var(--msv-font-secondary-color);
  --msv-buybox-primary-btn-border: var(--msv-accent-brand-color);

   // secondary button
   --msv-buybox-secondary-btn-bg: var(--msv-bg-color);
   --msv-buybox-secondary-btn-font-color: var(--msv-font-primary-color);
   --msv-buybox-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-buybox {
  margin-top: $buybox-margin-top;
  display: flex;
  flex-wrap: wrap;
  flex-flow: row;

  &__inventory-info {
    margin-top: $buybox-box-top-margin;
  }

  &__product-title {
    @include font-content(var(--msv-font-weight-light), var(--msv-buybox-title-font-size), $msv-line-height-xxl);
    color: var(--msv-buybox-title-font-color);
    margin-bottom: 8px;
  }

  &__inventory-label {
    @include font-content(var(--msv-font-weight-normal), var(--msv-body-font-size-l), $msv-line-height-m);
  }

  &__product-description {
    @include font-content(var(--msv-font-weight-light), var(--msv-buybox-text-font-size), $msv-line-height-m);
    color: var(--msv-buybox-text-font-color);
    margin-top: $buybox-box-top-margin;
    margin-bottom: $buybox-box-bottom-margin;
  }

  .msc-rating {
    &__count,
    &__star {
      color: var(--msv-buybox-rating-star);
    }

    &__half-star {
      &:after {
        color: var(--msv-buybox-rating-star);
      }
    }
  }
  
  .msc-price__actual {
    @include font-content-xl();
    color: var(--msv-buybox-text-font-color);
  }
  .msc-price__strikethrough{
    @include font-content-xl(var(--msv-font-weight-light));
  }

  &__configure {
    @include font-content-l(var(--msv-font-weight-normal));      
  }
  
  &__dropdown {
    margin-top: 20px;

    &-quantity-label {
      @include font-content-l(var(--msv-font-weight-light));
      color: var(--msv-buybox-title-font-color);
    }
  }
  
  &__media-gallery {
    display: block;
    flex-basis: $media-gallery-width;
    min-width: $media-gallery-width;
    flex-grow: 1;
    flex-wrap: nowrap;
    padding-right: $gutter-width;
  }

  .msc-dropdown__select {
    @include form-control();
  }

   /* Varient Inline */
   .custom-swatches button {
    border: 1px solid #ccc;
    width: 100% !important;
    background-color: transparent;
    cursor: pointer;
    outline: 0;
    padding: 5px 5px;
    height: 100%;
    float: left;
}

.dimension-swatch {
  display: flex;
  flex-direction: column;
  .custom-size-wrap {
    order: 1;
  }
  .custom-color-wrap {
    order: 0;
  }
  .custom-style-wrap {
    order: 3;
  }
}

.dimension-swatch .custom-size-wrap {
  order: 1;
}

.dimension-swatch .swatch-heading {
  padding-top: 30px;
  margin-bottom: 5px;
  @include font-content-l(var(--msv-font-weight-light));
  color: var(--msv-buybox-title-font-color);
}

.dimension-swatch .size-swatch {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.dimension-swatch li {
  width: 25%;
  min-height: 35px;
  padding: 0 5px;
  display: inline-block;
  line-height: 35px;
  color: #858585;
  text-align: center;
  margin-bottom: 10px;
  margin-right: 0;
  cursor: pointer;
}

.dimension-swatch .color {
  height: 40px;
  width: 50px;
}

.dimension-swatch .color-description {
  height: 110px;
  width: 100px;
}

.dimension-swatch li button {
  width: calc(100% - 60px);
  font-size: var(--msv-buybox-btn-font-size);
  line-height: 24px;
}

.dimension-swatch li.color button {
  border-radius: 45px;
}

.dimension-swatch li.color-description button {
  height: 80px;
  width: 80px !important;
}

.dimension-swatch li.color-description button div {
  top: 56px;
  left: -14px;
  position: relative;
  line-height: 1;
  width: 95px;
  float: left;
  height: 30px;
  overflow: hidden;
  font-size: 14px;
  color: $msv-black;
}

.dimension-swatch li.selected>button {
  text-decoration: none;
  background-color: var(--msv-buybox-primary-btn-bg);
  border: 3px solid magenta;
  color: $msv-white;
  box-shadow: 0px 0px 30px #EC008B;
}

  .quantity {
    .quantity-input {
      @include form-control();
      width: 80px;
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
      -moz-appearance: textfield;
      padding: $buybox-quantity-input;
      vertical-align: bottom;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }

    .quantity__controls {
      padding: $buybox-incr-decr;
      padding-left: 0px;
      padding-right: 0px;
      background: var(--msv-buybox-btn-bg);
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid var(--msv-buybox-btn-border-color);
      min-width: auto;
      width: 48px;
      height: 48px;
      cursor: pointer;

      &:disabled {
        color: $msv-gray-300;
      }
    }

    .increment {
      @include add-icon($msv-Add, after);
      color: var(--msv-buybox-btn-font-color);

      &.disabled {
        color: $msv-gray-300;
      }
      
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):active:focus {
        color: var(--msv-buybox-btn-font-color);
      }
    }

    .decrement {
      @include add-icon($msv-Remove, after);
      color: var(--msv-buybox-btn-font-color);
      
      &.disabled {
        color: $msv-gray-300;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):active:focus {
        color: var(--msv-buybox-btn-font-color);
      }
    }
  }

  &__quantity {
      margin-top: 20px;
  }

  &__product-quantity-label {
    @include font-content-l(var(--msv-font-weight-light));
    color: var(--msv-buybox-title-font-color);
    display: block;
    margin-bottom: $buybox-label-margin-bottom;
  }

  .ms-text-block {
    margin-top: 0px;
    margin-bottom: 1rem;
    @include font-content(var(--msv-font-weight-normal), var(--msv-buybox-text-font-size), $msv-line-height-m);
  }
  
  .product-add-to-cart {
    margin-top: 8px;
  
    .buttons {
      display: block;
      width: 100%;
    }

    .msc-add-to-cart {
      @include primary-button();
      margin-right: 8px;
      width: calc(100% - #{$buybox-wishlist-width-with-padding});
      font-size: var(--msv-buybox-btn-font-size);
      line-height: $msv-line-height-l;
    }
  
      .ms-wishlist-items__product-status {
        width: 100%;
        padding-top: $buybox-product-status-padding-top;
      }

    .msc-add-to-wishlist {
      @include font-glyph-standard();
      @include secondary-button();
      color: var(--msv-buybox-btn-wishlist-color);
      min-width: auto;
      padding-left: 0px;
      padding-right: 0px;
      position: relative;
      top: $buybox-wishlist-top-postion;
      width: $buybox-wishlist-width;

      &:not(:disabled):hover,
      &:not(:disabled):not(.disabled):active:focus {
        color: var(--msv-buybox-btn-wishlist-color);
      }
    }
  }
  
  &__quick-add-to-cart-container {
    padding-top: $buybox-content-items-padding-top;

    .msc-quick-add-to-cart {
      @include buybox-button();
      @include secondary-button();      
      margin-right: 8px;
      height: 50px;
      width: 100%;
      
      &:not(:disabled) {
        cursor: pointer;
      }           
    }
  }

  &__find-in-store {
    margin-top: 20px;
    margin-bottom: 20px;
    width: auto;
  }
  
  &__find-in-store-heading {
    @include font-content-l(var(--msv-font-weight-light));
  }
  
  &__find-in-store-description {
    margin-top: 8px;
    margin-bottom: $buybox-store-description-margin-bottom;
    @include font-content-s();
  }

  &__find-in-store-button {
    @include button-default();
    //@include secondary-button(var(--msv-buybox-secondary-btn-bg), var(--msv-buybox-secondary-btn-font-color), var(--msv-buybox-secondary-btn-border));
    @include secondary-button();
    font-size: var(--msv-buybox-btn-font-size);
    line-height: $msv-line-height-l;
  }

  &__dialog {
    max-width: $msv-dialog-width;
    
    .msc-modal__content {
        padding: $msv-dialog-modal-content-padding;
    }

    &__header {
      .msc-modal__title {
        @include font-content-l();

        span {
          &:nth-child(1) {
            font-weight: var(--msv-font-weight-bold);
          }
        }
      }
    }

    &__body {
      @include font-content-l();
      text-align: center;

      .msc-empty_image {
        @include image-placeholder($msv-dialog-msc-empty_image-size);
        padding-bottom: $msv-dialog-modal-content-empty-image-padding-bottom;
      }
    }

    &__image {
      margin-right: auto;
      margin-left: auto;
    }

    &__product-title {
      padding: $msv-dialog-modal-content-body-text-padding;
    }

    &__product-variants {
      @include font-content(var(--msv-font-weight-normal), var(--msv-buybox-text-font-size), $msv-line-height-m);
      padding: $msv-dialog-modal-content-body-text-padding;
    }

    &__product-price {
      font-weight: var(--msv-font-weight-bold);
      padding: $msv-dialog-modal-content-body-text-padding;
    }

    &__go-to-cart {
      //@include primary-button(var(--msv-buybox-primary-btn-bg), var(--msv-buybox-primary-btn-font-color), var(--msv-buybox-primary-btn-border));
      @include primary-button();
      margin: $msv-dialog-modal-content-button-margin;
      width: $msv-dialog-button-width;
    }

    &__back-to-shopping {
      @include secondary-button();
      margin: $msv-dialog-modal-content-button-margin;
      width: $msv-dialog-button-width;
    }
  }

  @media (max-width: $msv-breakpoint-l) {
    &__media-gallery {
      display: block;
      flex-basis: $media-gallery-width;
      min-width: $media-gallery-width;
      flex-grow: 1;
      flex-wrap: nowrap;
      padding-right: $gutter-width;
    }

    .ms-media-gallery {
      padding-bottom: 54px;

      .msc-carousel {
        &__indicators {
          bottom: -36px;
        }

        &__control {
          &__prev, &__next {
            background-color: transparent;
            bottom: -40px;
            height: auto;
            top: auto;
            width: auto;
          }
          
          &__prev__icon {
            background-image: $carousel-control-prev-icon-bg;
            margin-left: 0;
          }

          &__next__icon {
            background-image: $carousel-control-next-icon-bg;
            margin-right: 0;
          }
        }
      }
    }
  }
  
  //mobile vp
  @media (max-width: $msv-breakpoint-m) {
    flex-flow: column;

    &__product-title {
      font-size: 32px;
    }
  }
}