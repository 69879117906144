$msv-address-form-item-margin-bottom: 10px;
$msv-address-detail-item-margin-right: 5px;

$msv-address-select-item-margin-top: 10px;
$msv-address-select-item-margin-bottom: 10px;

$msv-alert-danger-color: $msv-white;
$msv-alert-danger-background-color: $msv-error-color;

$msv-alert-font-family: $msv-primary-font-family;
$msv-alert-font-weight: $msv-font-weight-bold;
$msv-alert-font-size: 16px;
$msv-alert-line-height: 21px;
$msv-alert-border-radius: 0;
$msv-alert-padding: 0.75rem 1.25rem;
$msv-alert-margin-bottom: 1rem;
$msv-alert-border: 1px solid transparent;

$msv-alert-icon: $msv-IncidentTriangle;
$msv-alert-icon-margin: 8px;

$msv-address-input-min-height: 32px;
$msv-address-input-border-color: #605e5c;
$msv-address-input-font-size: 18px;
$msv-address-input-line-height: 24px;
$msv-address-select-item-radio-height: 24px;
$msv-address-select-item-radio-width: 24px;
$msv-address-select-item-address-detail-padding-left: 48px;

$msv-address-button-save-margin-right: 20px;

$msv-account-management-address-margin-bottom: 20px;
$msv-account-management-address-list-button-add-empty-margin-bottom: 16px;
$msv-account-management-address-list-primary-border-color: $msv-gray-300;
$msv-account-management-address-list-primary-margin-top: 20px;
$msv-account-management-address-list-primary-heading-margin-bottom: 12px;
$msv-account-management-address-list-primary-list-border-color: $msv-gray-300;
$msv-account-management-address-list-primary-list-address-detail-item-margin-right: 5px;
$msv-account-management-address-list-primary-list-address-detail-item-newline-margin-right: 0px;
$msv-account-management-address-list-primary-list-button-edit-background-color: transparent;
$msv-account-management-address-list-primary-list-button-edit-border-color: transparent;
$msv-account-management-address-list-primary-list-phone-icon-margin-right: 5px;
$msv-account-management-address-list-button-primary-margin-top: 15px;
$msv-account-management-address-textbox-height: 48px;

.msc-address-form {
  &__item {
    margin-bottom: $msv-address-form-item-margin-bottom;
  }

  &__item-county {
    display: none;
  }

  &__label {
    display: block;
    font-size: $msv-address-input-font-size;
    line-height: $msv-address-input-line-height;
    margin-bottom: 5px;
  }

  &__input-text {
    padding: 6px 8px;
  }

  &__input,
  &__dropdown {
    @include vfi();
    display: block;
    width: 100%;
    border: none;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: $msv-address-input-font-size;
    line-height: $msv-address-input-line-height;
    font-weight: normal;
    height: $msv-account-management-address-textbox-height;
    background-color: $msv-white;
  }

  &__alert {
    display: block;
  }

  &__button-save {
    @include primary-button();
    margin-right: $msv-address-button-save-margin-right;
  }

  &__button-cancel {
    @include secondary-button();
  }

  .address-form__item-invalid &__alert {
    font-family: $msv-alert-font-family;
    font-weight: $msv-font-weight-bold;
    font-size: $msv-alert-font-size;
    line-height: $msv-alert-line-height;
    text-transform: none;
    border-radius: $msv-alert-border-radius;
    position: relative;
    padding: $msv-alert-padding;
    margin-bottom: $msv-alert-margin-bottom;
    border: $msv-alert-border;
    color: $msv-alert-danger-color;
    background-color: $msv-alert-danger-background-color;
    border-color: $msv-alert-danger-background-color;
    margin-top: $msv-alert-line-margin-top;
    &:before {
      margin-right: $msv-alert-icon-margin;
      @include msv-icon();
      content: $msv-IncidentTriangle;
    }
  }
}

.msc-address-detail {
  &__item {
    margin-right: $msv-address-detail-item-margin-right;
  }

  &__item-newline {
    margin-right: 0px;

    &:after {
      content: "\A";
      white-space: pre;
    }
  }
  &__item-address-detail_Phone{
    display: block;
  }
  &__item-phone {
    @include add-icon($msv-Phone);

    &-label {
      @include visually-hidden();
    }

    &:before {
      margin-right: $msv-account-management-address-list-primary-list-phone-icon-margin-right;
    }

    &.msc-address-detail__item-empty {
      display: none;
    }
  }
}

.msc-address-select {
  &__input-radio {
    height: $msv-address-select-item-radio-height;
    width: $msv-address-select-item-radio-width;
    position: absolute;
    top: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-top: $msv-address-select-item-margin-top;
    margin-bottom: $msv-address-select-item-margin-bottom;
    position: relative;

    .msc-address-detail {
      padding-left: $msv-address-select-item-address-detail-padding-left;
    }
  }

  &__input {
    @include vfi();
    @include form-input-checkbox();
  }

  &__button-add {
    @include vfi();
    @include primary-button();
  }
}

// mixin
@mixin address-button {
  cursor: pointer;
}
.ms-account-management-address {
  margin-bottom: $msv-account-management-address-margin-bottom;
  .ms-address-list {
    margin-bottom: $msv-account-management-address-margin-bottom;
  }

  .msc-address-list__heading {
    font-family: $msv-primary-font-family;
    font-weight: $msv-font-weight-normal;
    @include font-content-xl();
    margin-bottom: 28px;
  }

  .msc-address-list__add-empty {
    margin-bottom: $msv-account-management-address-list-button-add-empty-margin-bottom;
  }

  .msc-address-list__button-add {
    @include primary-button();
  }

  .msc-address-list__primary {
    border-top: 1px solid $msv-account-management-address-list-primary-border-color;
    margin-top: $msv-account-management-address-list-primary-margin-top;
    padding: 20px 0px;

    .msc-address-list__primary-heading {
      font-family: $msv-primary-font-family;
      @include font-content-m($msv-font-weight-bold);
      margin-bottom: $msv-account-management-address-list-primary-heading-margin-bottom;
    }
    .msc-address-list__primary-list {
      padding: 20px 0px;
      border-bottom: 1px dashed $msv-account-management-address-list-primary-list-border-color;

      &::nth-child(2) {
        padding-top: 0px;
      }

      &::last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
      .msc-address-detail {
        .msc-address-detail__item {
          margin-right: $msv-account-management-address-list-primary-list-address-detail-item-margin-right;
        }
        .msc-address-detail__item-newline {
          margin-right: $msv-account-management-address-list-primary-list-address-detail-item-newline-margin-right;

          &::after {
            content: "\A";
            white-space: pre;
          }
        }
      }
      .msc-address-list__button-primary {
        @include secondary-button();
        display: block;
        margin-top: $msv-account-management-address-list-button-primary-margin-top;
      }

      .msc-address-list__button-edit {
        font-family: $msv-primary-font-family;
        @include font-content-m();
        text-transform: none;
        border-radius: 0px;
        text-decoration: underline;
        background-color: $msv-account-management-address-list-primary-list-button-edit-background-color;
        border: 1px $msv-account-management-address-list-primary-list-button-edit-border-color;
        padding: 6px 12px 6px 0px;

        &:hover {
          cursor: pointer;
        }
      }
    }
    .msc-address-list__primary-list:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
