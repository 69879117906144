.ms-loyalty-signup {
    text-align: center;

    &__heading {
        margin-bottom: 10px;
    }

    &__text {
        margin-bottom: 10px;
    }

    &__terms {
        @include button-link();
        @include font-content-m($msv-font-weight-normal);
        display: block;
        margin-bottom: 10px;
    }

    &__btn {
        @include primary-button();
        margin-bottom: 10px;

    }

    &__signin {
        @include primary-button();
    }


}