$ms-refine-submenu-item-rating-line-height: 28px;
$ms-refine-submenu-item-rating-padding-left: 12px;
$refine-menu-rating-margin-bottom: 10px;
$refine-menu-submenu-item-line-height: 42px;
$refine-menu-submenu-item-multi-select-line-height: 42px;
$refine-menu-submenu-item-multi-select-font-size: 24px;
$refine-menu-submenu-item-multi-select-padding-top: 2px;

.ms-refine-submenu__list
{
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
}

.ms-refine-submenu-item
{
    display: block;
    line-height: $refine-menu-submenu-item-line-height;
    .refine-submenu-item
    {
        &__rating
        {
            padding-left: $ms-refine-submenu-item-rating-padding-left;
            display: inline-block;
            @include font-content-m($msv-font-weight-light);
        }
    }

    &__label {
        @include font-content-m($msv-font-weight-light);
    }

    .multi-select,
    .multi-select-checked {
        line-height: $refine-menu-submenu-item-multi-select-line-height;
    }

    .msc-rating {
        @include font-content-m($msv-font-weight-light);
        margin-bottom: $refine-menu-rating-margin-bottom;
    }
}

.ms-refine-submenu__toggle_collapsed
{
        @include add-icon($msv-Add, after);
}

.ms-refine-submenu__toggle_expanded
{
        @include add-icon($msv-Remove, after);
        @include font-content-l();
}

.ms-refine-submenu-item
{
    &.multi-select
    {
        @include add-icon($msv-Checkbox, before, 400);
        &:before {
            vertical-align: top;
            padding-top: $refine-menu-submenu-item-multi-select-padding-top;
            font-size: $refine-menu-submenu-item-multi-select-font-size;
        }
    }
    &.multi-select-checked
    {
        @include add-icon($msv-Checkbox-Checked, before, 400);
        &:before {
            vertical-align: top;
            padding-top: $refine-menu-submenu-item-multi-select-padding-top;
            font-size: $refine-menu-submenu-item-multi-select-font-size;
        }
    }
}
.ms-refine-submenu-item
{
    &.single-select
    {
        @include add-icon($msv-RadioBtnOff, before, 400);
        &:before {
            vertical-align: top;
            padding-top: $refine-menu-submenu-item-multi-select-padding-top;
            font-size: $refine-menu-submenu-item-multi-select-font-size;
        }
    }
    &.single-select-checked
    {
        @include add-icon($msv-RadioBtnOn, before, 400);
        &:before {
            vertical-align: top;
            padding-top: $refine-menu-submenu-item-multi-select-padding-top;
            font-size: $refine-menu-submenu-item-multi-select-font-size;
        }
    }
}

.msc-choice-summary__clear-all
{
    @include add-icon($msv-Cancel, after);
}

.ms-refine-submenu__range-refiner {
    .slider__labels-item {
        font-size: $msv-font-size-s;
        line-height: $msv-line-height-s;
        color: $msv-gray-900;
    }
}