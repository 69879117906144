$ms-text-block-margin-top: 32px;

.ms-text-block
{
    margin-top: $ms-text-block-margin-top;

    &.alignment__center
    {
      text-align: center;
    }
    &.alignment__left {
        text-align: left;
    }
    &.alignment__right {
        text-align: right;
    }

    &.fontsize__small {
        font-size: small;
    }
    &.fontsize__medium {
        font-size: medium;
    }
    &.fontsize__large {
        font-size: large;
    }
    &.fontsize__extralarge {
        font-size: x-large;
    }

    a {
        color: $msv-text-color;
        text-decoration: underline;
    }
}