$max-height-m: 732px;
$max-height-l: 772px;
$carousel-padding-left: 20px;
$thumbnail-height: 100px;
$thumbnail-flipper-height: 30px;
$thumbnail-flipper-background: $msv-white;
$thumbnail-flipper-foreground: $msv-gray-500;
$carousel-tooltip-padding:4px;
$carousel-indicators-border: 1px;

.ms-media-gallery {
    display: inline-flex;

    .ms-media-gallery__thumbnails-container {
        display: none;
        order: 0;
        flex-grow: 0;
    }

    .ms-media-gallery__carousel {
        align-items: start;

        order: 1;
        flex-grow: 1;
    }

    .msc-ss-carousel-vert__flipper {
        position: relative;
        height: $thumbnail-flipper-height;
        width: 100%;
        border-radius: 0;
        background: $thumbnail-flipper-background;
        border: 1px solid $thumbnail-flipper-background;
        top: auto;
        left: auto;
        transform: none;
        color: $thumbnail-flipper-foreground;

        flex: 0 1 $thumbnail-flipper-height;

        &.disabled {
            display: none;
        }
    }

    .msc-carousel__control__prev,
    .msc-carousel__control__next {
        background-color: $msv-black;
    }

    .msc-ss-carousel-vert__flipper--next {
        bottom: auto;
    }
    
    .msc-tooltip-inner {
        background-color: $msv-black;
        color: $msv-white;
        padding: $carousel-tooltip-padding $carousel-tooltip-padding;
    }

    .msc-carousel__indicators li{
        &.active{
          background-color: $msv-black;
        }
        border: $carousel-indicators-border solid $msv-black;
        background-color: $msv-white;
    }
}

@media (min-width: $msv-breakpoint-m) {
    .ms-media-gallery {
        .msc-carousel__item {
            max-height: $max-height-m;
        }
    }
}

@media (min-width: $msv-breakpoint-l) {
    .ms-media-gallery {
        height: $max-height-l;

        .ms-media-gallery__carousel {
            margin-left: $carousel-padding-left;
        }

        .msc-carousel__indicators {
            display: none;
        }
    
        .msc-carousel__control__prev {
            display: none;            
        }

        .msc-carousel__control__next {
            display: none;            
        }

        .ms-media-gallery__thumbnails-container {
            display: flex;
        }
    
        .ms-media-gallery__thumbnail-item {
            height: $thumbnail-height;
        }
    }
}

//mobile vp
@media (max-width: $msv-breakpoint-m) {
    .ms-media-gallery {
      padding-right: 0;
    }
}