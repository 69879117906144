@import '../00-settings/colors';
@import '../00-settings/font';
@import '../00-settings/responsive-breakpoints';

$msv-secondary-btn-background-color: $msv-white;
$msv-secondary-btn-color: $msv-text-color;
$msv-secondary-btn-border-color: $msv-primary;
$msv-primary-btn-background-color: $msv-primary;
$msv-primary-btn-color: $msv-white;
$msv-primary-btn-border-color: $msv-primary;
$msv-btn-min-width: 160px;
$msv-btn-side-padding: 20px;
$msv-btn-vertical-padding: 12px;

@mixin button-link($color: $msv-link-color) {
  border-color: transparent;
  background-color: transparent;
  color: $color;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

@mixin primary-button() {
  background-color: $msv-primary-btn-background-color;
  border: 1px solid $msv-primary-btn-border-color;
  color: $msv-primary-btn-color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;

  &:hover,
  &:focus {
    background-color: $msv-primary-hover;
    border-color: $msv-primary-hover;
    text-decoration: underline;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    border-color: $msv-gray-300;
    background-color: $msv-gray-300;
    color: $msv-white;
  }
}

@mixin secondary-button() {
  background-color: $msv-secondary-btn-background-color;
  border: 1px solid $msv-secondary-btn-border-color;
  color: $msv-secondary-btn-color;
  padding: $msv-btn-vertical-padding $msv-btn-side-padding;
  min-width: $msv-btn-min-width;

  &:hover,
  &:focus {
    background-color: $msv-secondary-hover;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    border-color: $msv-gray-300;
    background-color: $msv-gray-300;
    color: $msv-white;
  }
}

@mixin button-default() {
  @include vfi();
  @include font-content-l();
  cursor: pointer;
  padding: 12px 20px;
  height: 48px;
  min-width: 160px;
  text-align: center;
}

@mixin button-icon {
  width: 48px;
  height: 48px;
}
