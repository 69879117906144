$msv-primary-font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
$msv-font-assistant: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
$msv-font-allerta: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
$msv-font-cinzel: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
$msv-font-weight-bold: bold;
$msv-font-weight-normal: normal;
$msv-font-weight-light: 300;
$msv-font-weight-heavy: 600;
$msv-font-weight-bold: bold;

$msv-heading-text-size: 20px;
$msv-heading-line-height: 28px;

$msv-font-size-s: 14px;
$msv-font-size-m: 16px;
$msv-font-size-l: 18px;
$msv-font-size-xl: 24px;
$msv-font-size-xxl: 48px;
$msv-title-font-size-xs: 24px;
$msv-title-font-size-s: 36px;
$msv-title-font-size-m: 100px;
$msv-title-font-size-l: 160px;
$msv-line-height-s: 18px;
$msv-line-height-m: 21px;
$msv-line-height-l: 24px;
$msv-line-height-xl: 31px;
$msv-line-height-xxl: 63px;
$msv-title-line-height-xs: 31px;
$msv-title-line-height-s: 46px;
$msv-title-line-height-m: 134px;
$msv-title-line-height-l: 215px;

$msv-icon-size-small: 16px;
$msv-icon-size: 24px;
$msv-text-size: $msv-font-size-m;
$msv-text-line-height: 20px;

@mixin fabrikam-font(
  $font-weight: $msv-font-weight-normal,
  $font-size: $msv-font-size-m,
  $line-height: $msv-line-height-m,
  $text-transform: none,
  $font-family: $msv-font-assistant
) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  text-transform: $text-transform;
}

@mixin font-content($font-weight: $msv-font-weight-normal, $font-size: $msv-font-size-s, $line-height: $msv-line-height-s, $text-transform: none) {
  @include fabrikam-font($font-weight, $font-size, $line-height, $text-transform);
}

@mixin font-content-s($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight);
}

@mixin font-content-s-uppercase($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight, $msv-font-size-s, $msv-line-height-s, uppercase);
}

@mixin font-content-s-height-l($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight, $msv-font-size-s, $msv-line-height-l);
}

@mixin font-content-m($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight, $msv-font-size-m, $msv-line-height-m);
}

@mixin font-content-m-underline($font-weight: $msv-font-weight-normal) {
  text-decoration-line: underline;
  @include font-content($font-weight, $msv-font-size-m, $msv-line-height-m);
}

@mixin font-content-m-strikethrough($font-weight: $msv-font-weight-normal) {
  text-decoration-line: line-through;
  @include font-content($font-weight, $msv-font-size-m, $msv-line-height-m);
}

@mixin font-content-l($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight, $msv-font-size-l, $msv-line-height-l);
}

@mixin font-content-xl($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight, $msv-font-size-xl, $msv-line-height-xl);
}

@mixin font-content-xxl($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight, $msv-font-size-xxl, $msv-line-height-xxl);
}

@mixin fabrikam-display($font-size: $msv-title-font-size-xs, $line-height: $msv-title-line-height-xs, $font-family: $msv-font-allerta) {
  @include fabrikam-font($font-weight-normal, $font-size, $line-height, uppercase, $font-family);
}

@mixin font-display-xs() {
  @include fabrikam-display();
}

@mixin font-display-s() {
  @include fabrikam-display($msv-title-font-size-s, $msv-title-line-height-s);
}

@mixin font-display() {
  letter-spacing: 0.05em;
  @include fabrikam-display($msv-title-font-size-m, $msv-title-line-height-m, $msv-font-cinzel);
}

@mixin font-display-l() {
  letter-spacing: 0.05em;
  @include fabrikam-display($msv-title-font-size-l, $msv-title-line-height-l, $msv-font-cinzel);
}

@mixin font-glyph-standard() {
  font-size: $msv-icon-size;
}

@mixin font-glyph-small() {
  font-size: $msv-icon-size-small;
}

@mixin font-content-heading($font-weight: $msv-font-weight-heavy) {
  @include font-content($font-weight, $msv-heading-text-size, $msv-heading-line-height);
}
