.msc-add-to-cart {
    @include primary-button();
    @include vfi();

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
} 