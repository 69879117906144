// desktop
$cookie-compliance_container-padding-l: 60px;
$cookie-compliance_container-padding-m: 20px;
$cookie-compliance_container-padding-s: 16px;
$cookie-compliance_container-max-width: $msv-breakpoint-xl;
$cookie-compliance_content-width:100%;
$cookie-compliance_accept-btn-margin-left: 48px;
$cookie-compliance_accept-btn-width: 160px;

// mobile 
$cookie-compliance_accept-btn-width-mobile: 243px;
$cookie-compliance_accept-content-margin-bottom-mobile:24px;


.ms-cookie-compliance {
  &__container {
    display: flex;
    font-weight: normal;
    margin-bottom: 0;
    max-width: $cookie-compliance_container-max-width;
    margin: 0 auto;

    @media screen and (min-width: $msv-breakpoint-s) {
        padding-left: $cookie-compliance_container-padding-s;
        padding-right: $cookie-compliance_container-padding-s;
    }

    @media (min-width: $msv-breakpoint-m) {
        padding-left: $cookie-compliance_container-padding-m;
        padding-right: $cookie-compliance_container-padding-m;
    }
  }

  &__content {
    width:$cookie-compliance_content-width;
  }

  &__accept-button {
    float: right;
    margin-left: $cookie-compliance_accept-btn-margin-left;
    width: $cookie-compliance_accept-btn-width;
    @include primary-button();
  }

  &__cta-layer .link {
    text-decoration: underline;
  }
}

@media (max-width: $msv-breakpoint-m) {
  .ms-cookie-compliance {

    &__content {
      margin-bottom: $cookie-compliance_accept-content-margin-bottom-mobile;
    }
    
    &__accept-button {
      float: none;
      margin: 0;
      width: $cookie-compliance_accept-btn-width-mobile;
    }

    &__container {
      align-items: center;
      flex-direction: column;
    }
  }
}