.hero.ms-content-block .ms-content-block__details .ms-content-block__title {
  font-size: 96px !important;
}

@media screen and (max-width: 768px) {
  .hero.ms-content-block .ms-content-block__details .ms-content-block__title {
    font-size: 48px !important;
  }
}

@media screen and (max-width: 568px) {
  .hero.ms-content-block .ms-content-block__details .ms-content-block__title {
    font-size: 32px !important;
  }
}

body {
  line-height: initial !important;
}

.ms-checkout__guided-card-btn-cancel {
  display: none;
}

.ms-order-details__sales-status {
  display: none;
}

.msc-address-detail__item-streetnumber {
  display: none !important;
}

.msc-address-detail__item-zipcode {
  display: none !important;
}