$msc-account-welcome-title-margin-bottom: 40px;
$msc-account-welcome-title-margin-top:32px;
$msc-account-welcome-title-border-bottom-color: $msv-gray-500;
$msc-account-welcome-title-border-bottom-style: solid;
$msc-account-welcome-title-border-bottom-thickness: 1px;
$msc-account-welcome-title-links-padding-top: 12px;

$msc-account-tile-heading-icon-padding: 5px;

$msc-generic-tile-heading-margin-bottom: 0.5rem;

.ms-account {

  &-welcome-tile {

    display: block;
    border-bottom: $msc-account-welcome-title-border-bottom-thickness $msc-account-welcome-title-border-bottom-style $msc-account-welcome-title-border-bottom-color;
    margin-bottom: $msc-account-welcome-title-margin-bottom;
    margin-top: $msc-account-welcome-title-margin-top;

    &__heading{
      @include font-content-xl();
      }

    @media (min-width: $msv-breakpoint-m) {
      &__heading{
        @include font-content-xxl();
      }
    }
  }

  &-generic-tile {
    &__link {
      @include vfi();
    }
    &.order-tile {
      .ms-account-generic-tile__heading {
        @include add-icon($msv-DeliveryTruck, before);
        &:before {
          padding-right: $msc-account-tile-heading-icon-padding;
        }
      }
    }

    &.profile-tile {
      .ms-account-generic-tile__heading {
        @include add-icon($msv-Contact, before);
        &:before {
          padding-right: $msc-account-tile-heading-icon-padding;
        }
      }
    }
  }

  &-wishlist-tile__heading {
    @include add-icon($msv-HeartFill, before);
    &:before {
      padding-right: $msc-account-tile-heading-icon-padding;
    }
  }

  &-address-tile__heading {
    @include add-icon($msv-MapPin, before);
    &:before {
      padding-right: $msc-account-tile-heading-icon-padding;
    }
  }

  &-loyalty-tile__heading {
    @include add-icon($msv-IdCard, before);
    &:before {
      padding-right: $msc-account-tile-heading-icon-padding;
    }
  }

  &-generic-tile,
  &-wishlist-tile,
  &-address-tile,
  &-loyalty-tile {

    margin-bottom: $msc-account-welcome-title-margin-bottom;

    &__heading {
      @include font-content-l($msv-font-weight-bold);
      margin-bottom: $msc-generic-tile-heading-margin-bottom;
    }

    &__links {
      @include font-content-m-underline();
      padding-top: $msc-account-welcome-title-links-padding-top;
    }

    &__link {
      @include vfi();
    }

    &__description {
      @include font-content-m();
    }
  }
}
