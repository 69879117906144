// bootstrap color variables
$msv-white: #fff;
$msv-black: #000;
$msv-blue:    #0062af;
$msv-green: #107C10;
$msv-red: #A80000;
$msv-pink: #FCDFDF;
$msv-cyan: #0776d1;

$msv-gray-100: #F8F7F6;
$msv-gray-200: #E8E8E8;
$msv-gray-300: #D1D1D1;
$msv-gray-400: #6B727A;
$msv-gray-500: #616365;
$msv-gray-800: #8F8F8F;
$msv-gray-900: #1D1D1D;
$msv-gray-1000: #2D2D2D;

$msv-primary: #F7188B;
$msv-primary-hover: #F7188B;
$msv-secondary: #6c757d;
$msv-secondary-hover: #FFF5FD;

$msv-text-color: $msv-gray-900;
$msv-link-color: $msv-gray-900;
$msv-divider-color: $msv-gray-300;
$msv-error-color: $msv-red;
$msv-success-color: $msv-green;
$modal-input-background: $msv-gray-100;
$msv-modal-overlay: rgba(0, 0, 0, 0.7);
$msv-box-shadow-color: rgba(0, 0, 0, 0.132);

// Social button color variables
$facebook-bg-color: #1877F2;
$facebook-hover-color: #0B5AC1;
$microsoft-bg-color: #3C3C41;
$microsoft-hover-color: #000000;

$msv-unfilled-rating-color: $msv-gray-800;

$msv-savings-color: #F7188B;