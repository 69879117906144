$msv-forgot-password-color: $msv-gray-500;
$msv-sign-in-social-accounts-border: 1px dashed $msv-gray-300;
$msv-sign-up-border: 1px solid $msv-gray-300;

$msv-sign-in-font-size: 14px;
$msv-sign-in-line-height: 20px;
$msv-sign-in-link-font-size: 12px;
$msv-sign-in-link-line-height: 26px;
$msv-sign-in-heading-font-size: 24px;
$msv-sign-in-heading-font-weight: normal;
$msv-sign-in-heading-line-height: 32px;

$msv-sign-in-button-margin-bottom: 60px;
$msv-sign-in-button-margin-top: 20px;

$msv-sign-input-height: 32px;
$msv-sign-in-account-item-margin-top: 20px;
$msv-sign-in-input-margin-top: 4px;
$msv-sign-in-input-padding: 8px;

$msv-sign-in-section-spacing: 90px;
$msv-sign-in-section-spacing-m: 40px;

$msv-sign-in-social-accounts-padding: 40px 0;
$msv-sign-in-social-button-padding: 8px 16px;
$msv-sign-in-social-accounts-button-margin-bottom: 20px;
$msv-sign-in-social-accounts-text-margin-left: 16px;
$msv-sign-in-link-margin-top: 8px;

$msv-sign-in-sign-up-description-margin-top: 20px;
$msv-sign-in-sign-up-description-margin-bottom: 32px;

$msv-sign-in-message-padding: 20px;
$msv-sign-in-message-margin-top: 20px;
$msv-sign-in-error-border: 1px solid $msv-white;
$msv-sign-in-error-bg-color: $msv-error-color;
$msv-sign-in-error-text-color: $msv-white;

$msv-sign-in-container-margin: 50px 100px;
$msv-sign-in-container-margin-m: 20px;

.ms-sign-in {
  @include font-content-m();

  &__container {
    display: flex;
    margin: $msv-sign-in-container-margin;
  }
  
  &__social-account-picture {
    display: inline-block;
    vertical-align: middle;
  }

  &__sign-in-heading, &__sign-up-heading {
    @include font-content-xl();
  }

  &__sign-in-section {
    flex: 1;
    margin-right: $msv-sign-in-section-spacing;
  }

  &__sign-up-section {
    border-left: $msv-sign-up-border;
    flex: 1;
    padding-left: $msv-sign-in-section-spacing;
  }

  &__account-item {
    margin-top: $msv-sign-in-account-item-margin-top;

    &-input {
      @include form-input-el();
      margin-top: $msv-sign-in-input-margin-top;
      width: 100%;
    }
  }

  &__forget-password {
    display: inline-block;
    color: $msv-forgot-password-color;
    @include font-content-s();
    margin-top: $msv-sign-in-link-margin-top;
  }

  &__sign-in-button {
    @include primary-button();
    margin-bottom: $msv-sign-in-button-margin-bottom;
    margin-top: $msv-sign-in-button-margin-top;
    width: 100%;
  }

  &__sign-up-link {
    @include primary-button();
    width: 100%;
  }

  &__social-accounts {
    border-top: $msv-sign-in-social-accounts-border;
    padding: $msv-sign-in-social-accounts-padding;

    button:last-child {
      margin-bottom: 0px;
    }
  }

  &__social-account {
    margin-bottom: $msv-sign-in-social-accounts-button-margin-bottom;
    width: 100%;

    &-FacebookExchange {
      background-color: $facebook-bg-color;
      color: $msv-white;
      padding: $msv-sign-in-social-button-padding;

      &:hover,
      &:focus {
        background-color: $facebook-hover-color;
      }
    }

    &-MicrosoftAccountExchange {
      background-color: $microsoft-bg-color;
      border: 1px solid $microsoft-bg-color;
      color: $msv-white;
      padding: $msv-sign-in-social-button-padding;

      &:hover,
      &:focus {
        background-color: $microsoft-hover-color;
        border: 1px solid $microsoft-hover-color;
      }
    }

    &-text {
      margin-left: $msv-sign-in-social-accounts-text-margin-left;
    }
  }

  &__sign-up-description {
    margin-top: $msv-sign-in-sign-up-description-margin-top;
    margin-bottom: $msv-sign-in-sign-up-description-margin-bottom;
  }

  &__page-error {
    background-color: $msv-sign-in-error-bg-color;
    border:  $msv-sign-in-error-border;
    color: $msv-sign-in-error-text-color;
    display: none;
    margin-top: $msv-sign-in-message-margin-top;
    padding: $msv-sign-in-message-padding;
  }

  @media screen and (max-width: $msv-breakpoint-m) {
    &__container {
      display: block;
      margin: $msv-sign-in-container-margin-m;
    }

    &__sign-in-section {
      margin-right: 0px;
    }

    &__sign-up-section {
      border-left: none;
      border-top: $msv-sign-up-border;
      padding-left: 0px;
      padding-top: $msv-sign-in-section-spacing-m;
    }
  }
}
