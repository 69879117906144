.pas {
    &-store-locator {
        &__store-map {
            flex: 1;
        }

        &__store-data {
            display: none;
        }

        &__store-list-container {
            overflow-y: auto; 
            flex: 0 0 280px; 
            padding: 20px;
        }

        &__store-locator {
            display: flex;
            max-height: 80vh;
            height: 80vh;
        }

        &__store-map-container {
            position: relative; 
            width: 100%; 
            height: 100%;
        }
    }
}

@media only screen and (max-width: 600px) {
    .pas {
        &-store-locator {
            &__store-map {
                flex: 1;
                width: 100%
            }

            &__store-list-container {
                flex: 1;
                width: 100%
            }
    
            &__store-locator {
                flex-direction: column-reverse;
                max-height: inherit;
                height: inherit;
            }

            &__store-map-container {
                height: 66vh;
            }
        }
    }
}