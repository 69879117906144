$tableBorderColor: $msv-gray-300;
$tableOddRowColor: rgba($black, .05);
$tableEvenRowColor: $msv-white;

.ms-product-specification{

    &__heading {
        @include font-content-xl($msv-font-weight-normal);
        margin-top: 40px;
        margin-bottom: 20px;
    }

    &__table {
        width: 100%;
        border-spacing: 0px;
        border-collapse: collapse;
    }

    &__table-row {
        &:nth-of-type(odd) {
            background-color: $tableOddRowColor;
        }

        &:nth-of-type(even) {
            background-color: $tableEvenRowColor;
        }

        th {
            @include font-content-m($msv-font-weight-bold);

            border: 1px solid $tableBorderColor;
            padding: .75rem;
            text-align: left;
        }

        td {
            @include font-content-m();

            border: 1px solid $tableBorderColor;
            padding: .75rem;
        }
    }
}